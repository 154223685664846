.MuiPaper-rounded,
MuiList-root,
MuiMenu-paper {
	border-radius: 2px !important;
}

.MuiPopover-paper {
	background: none !important;
	box-shadow: none !important;
}
