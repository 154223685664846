body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #f3f3f3;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

span,
a,
a:visited {
	text-decoration: none;
	color: inherit;
}

p {
	margin: 0;
}

@font-face {
	font-family: 'Montserrat';
	font-style: light;
	font-weight: 300;
	src: url('./Assets/fonts/Montserrat-Light.ttf');
}

@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url('./Assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
	font-family: 'BodoniMt';
	font-style: normal;
	font-weight: 400;
	src: url('./Assets/fonts/BodoniMt-Bold.TTF');
}

@font-face {
	font-family: 'BodoniMt';
	font-style: normal;
	font-weight: 300;
	src: url('./Assets/fonts/BodoniMt-Regular.TTF');
}

@font-face {
	font-family: 'Cormorant';
	font-style: normal;
	font-weight: 400;
	src: url('./Assets/fonts/Cormorant.ttf');
}
